.hamburger-menu {
  div[role='document'] {
    height: 100%;
    margin: 0;
  }

  .modal-content {
    background: #00112f;
    border: none;
    border-radius: 0;
    height: 100%;
  }

  .modal-body {
    padding: 0;
  }

  .title {
    text-align: left;
  }

  .modal--header {
    align-items: center;
    border: none;
    display: flex;
    justify-content: space-between;
  }

  .modal--content {
    align-items: center;
    color: #e6e4df;
    display: flex;
    flex-direction: column;
    font-family: 'Aeonik', sans-serif;
    font-size: 3.1rem;
    padding: 10vh 0;
  }

  .hamburger-title {
    margin-bottom: 3rem;
    text-align: center;
  }

  .hamburger-contact-us {
    margin-bottom: 5rem;

    .links {
      display: flex;
      flex-direction: column;
    }

    .contact-us--button {
      align-items: center;
      display: inline-flex;
      line-height: 1.5rem;

      svg {
        margin-right: 0.6rem;
      }
    }

    a {
      border-radius: 8px;
      font-size: 1.8rem;
      margin-bottom: 1.2rem;
      padding: 1.5rem 2.4rem;
      text-align: center;

      &:hover {
        background-color: #979797;
        color: initial;
        text-decoration: none;
      }
    }
  }

  .language-selector {
    button {
      border: 1px solid white;
      border-radius: 8px;
      color: white;
      display: block;
      margin-bottom: 1.2rem;
      padding: 1.5rem 3rem;
      width: 100%;

      &.active {
        background-color: #e6e4df;
        color: #00112f;
      }
    }
  }
}
