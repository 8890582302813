.companies-grid {
  display: grid;
  grid-template-columns: repeat(3, 33.3333%);

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  div {
    align-items: center;
    display: flex;
    justify-content: center;

    img {
      height: auto;
      max-width: 100%;
      width: 100%;
    }

    @media (max-width: 767px) {
      margin-bottom: 4rem;
    }
  }
}

.companies {
  p {
    padding: 8rem 0 4rem;
    text-align: center;

    @media (max-width: 767px) {
      margin: 0 auto;
      max-width: 100%;
      padding: 4rem 0;
    }
  }
}
