.malt-timeline-wrapper {
  background-color: #ebecef;
  border-radius: 30px;
  padding-bottom: 115px;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 46px;

  p {
    color: $dark-blue;
    font-family: 'Aeonik', sans-serif;
  }

  .section-title {
    font-size: 46px;
    font-style: normal;
    font-weight: normal;
    line-height: 55px;
    margin-bottom: 18px;
  }

  .section-description {
    font-size: 26px;
    font-style: normal;
    font-weight: normal;
    line-height: 31px;
    margin-bottom: 80px;
  }

  .malt-timeline {
    display: flex;
    justify-content: center;

    svg {
      // max-width: 80%;
      flex-shrink: 0;
    }

    &--small {
      @extend .malt-timeline;

      svg {
        flex-shrink: 0;
        width: auto !important;
      }
    }
  }

  @media (max-width: 1200px) {
    svg {
      width: 900px;
    }
  }

  @media (max-width: 967px) {
    svg {
      width: 700px;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 60px;
    padding-top: 50px;
    // padding-left: 40px;
    // padding-right: 40px;

    .section-title {
      font-size: 26px;
      font-style: normal;
      font-weight: bold;
      line-height: 31px;
      margin-bottom: 36px;
    }

    .section-description {
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 56px;
    }

    svg {
      width: 700px;
    }
  }
}
