.slider-img {
  display: flex;
  justify-content: center;

  img {
    align-self: flex-start;
    width: 100%;
  }
}

.slick-track {
  align-items: center;
  display: flex;
  justify-content: center;
}

.slick-dots {
  bottom: -3rem;
}
