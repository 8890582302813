.custom-offer-wrapper {
  background-color: #9f91dc;
  padding: 80px 0;

  .custom-offer {
    text-align: center;

    p {
      color: #fff;
    }

    .custom-offer-container {
      padding: 40px 0 30px 0;
      background-color: #00112f;
      width: 100%;
      max-width: 850px;
      margin: 0 auto;
      border-radius: 10px;

      h3 {
        font-size: 1.6rem;
        line-height: 24px;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 0;
        margin-top: -5rem;

        span {
          padding: 0.5em 1.2em;
          background-color: #fff873;
          border-radius: 10px;
        }
      }

      .main-text {
        padding: 30px 0 10px 0;
        background-color: #182944;
        width: 100%;
        max-width: 600px;
        margin: 0 auto 3rem auto;
        border-radius: 10px;

        p {
          font-size: 5rem;

          span {
            font-weight: bold;
          }
        }

        .currency span {
          font-size: 3rem;
        }

        p.main-text-help {
          font-size: 1.4rem;
        }
      }

      p.main-price {
        font-size: 3rem;
        margin: 1.2em 0 1em 0;

        .currency span {
          font-size: 1.6rem;
        }
      }

      button {
        font-size: 2.2rem;
        font-weight: 700;
        margin: 0 auto 3rem auto;
        color: #fff;
      }

      .help {
        .clock-svg {
          position: relative;
          top: -2px;
          margin-right: 10px;
        }

        .help-bold {
          font-weight: 700;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 6rem 0;

    .custom-offer {
      .custom-offer-container {
        padding-left: 10%;
        padding-right: 10%;

        p.main-price {
          font-size: 1.6rem;
        }

        .main-text {
          p {
            font-size: 3rem;
            line-height: 4rem;

            span {
              margin-top: 2rem;
            }
          }
        }

        button {
          max-width: 27rem;
          width: 100%;
        }

        .help-bold {
          font-size: 1.6rem;
        }
      }
    }
  }
}
