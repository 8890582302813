.payment-confirmation-page {
  padding: 10vh 0 12rem;

  @media (max-width: 767px) {
    padding-bottom: 10vh;
    padding-top: 15vh;
  }

  @media (min-width: 992px) {
    #payment-confirmation-content {
      #subtitle {
        max-width: 60%;
      }
    }
  }

  img {
    height: 100%;
    width: 100%;
  }

  #title,
  #subtitle {
    display: block;
  }

  #title {
    color: $eerie-black;
    font-size: 5.6rem;
    line-height: 6.7rem;
    margin-bottom: 2.4rem;

    @media (max-width: 767px) {
      font-size: 3.6rem;
      line-height: 4.1rem;
      font-weight: 700;
    }
  }

  #subtitle {
    font-size: 2.6rem;
    line-height: 3.2rem;
    margin-bottom: 4.3rem;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  button {
    margin: 1rem 0;
    padding: 2rem 4rem;

    a {
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 2.6rem;
      text-decoration: none;
    }
  }
}

.main[payment-confirmation] {
  background: white;

  .title span a {
    color: black;
  }
}
