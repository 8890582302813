#promobar-content-malt-landing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: fit-content;
    margin: 0;
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: $dark-blue;

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  div {
    margin-right: 12px;

    svg {
      display: block;
      margin: auto;
      height: 20px;
      width: 70px;
    }
  }

  @media (max-width: 767px) {
    div {
      position: absolute;
      left: 20px;

      svg {
        height: 20px;
        width: 20px;
        justify-self: flex-start;
      }
    }
  }
}
