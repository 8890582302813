.podcasts-extracts-grid {
  display: grid;
  grid-gap: 8%;
  grid-template-columns: 1fr;
  padding-bottom: 4rem;
  padding-top: 2rem;
  text-align: left;

  @media (max-width: 767px) {
    grid-gap: unset;
    grid-template-columns: 1fr;
  }
}

.main-grid {
  display: grid;
  grid-gap: 10%;
  grid-template-columns: 54% 36%;

  @media (max-width: 767px) {
    grid-gap: unset;
    grid-template-columns: 1fr;
    text-align: left;
  }
}

.podcasts-extracts {
  padding-top: 5.5rem;

  @media (max-width: 767px) {
    text-align: center;
  }

  .podcast-desc {
    white-space: pre-wrap;
  }
}

.podcasts-extracts-grid--item {
  @media (max-width: 767px) {
    padding-top: 15%;
    text-align: center;
  }
}

p.podcast-quote {
  color: $royal-blue;
  font-family: 'DM Sans', sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  letter-spacing: -0.075rem;
  line-height: 3.6rem;
  margin-bottom: 2.6rem;
  margin-top: 3.6rem;
}

.podcast-person {
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .bold {
    margin-bottom: 0.25rem;
  }
}

.podcast-image {
  align-items: flex-end;
  display: flex;

  @media (max-width: 767px) {
    justify-content: center;
  }
}

a.theam-link {
  color: var(--color-paragraph);
  font-family: 'Barlow', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    color: $royal-blue;
  }
}
