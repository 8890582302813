.welcome-text-wrapper {
  background-color: #fff;
  padding: 10rem 0;

  .welcome-text {
    text-align: center;

    .welcome-text-content {
      display: flex;
      width: 100%;
      max-width: 850px;
      margin: 0 auto;
      text-align: left;
    }

    .logo {
      font-family: 'Barlow', sans-serif;
      font-size: 3rem;
      font-weight: 900;
      color: #6e798b;
      display: block;
      margin-bottom: 5rem;
      text-decoration: none;
    }

    p.subtitle {
      padding: 40px 0 25px 0;
      font-size: 3rem;
    }

    .column {
      width: 100%;
      flex-grow: 1;

      p {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }

      b {
        font-weight: 700;
      }

      &:first-child {
        margin-right: 1.5rem;
      }

      &:last-child {
        margin-left: 1.5rem;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 5rem 0;

    .welcome-text {
      .logo {
        font-size: 3rem;
      }

      p.subtitle {
        font-size: 2.2rem;
        padding: 3rem 0 3.5rem 0;
      }

      .welcome-text-content {
        flex-direction: column;

        .column {
          margin: 0;
        }
      }
    }
  }
}
