.shiba {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

.shiba-image {
  height: 30vh;
  padding-bottom: 10px;
  width: 40vh;
}
