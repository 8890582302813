.buy-now-wrapper {
  padding-top: 7rem;
  padding-bottom: 9rem;
  background-color: #00112f;
  color: #fff;

  .buy-now {
    text-align: center;

    h3 {
      font-size: 6.5rem;
      margin-bottom: 40px;
    }

    button {
      font-size: 2.2rem;
      font-weight: 700;
      margin: 0 auto 3rem auto;
      color: #fff;
      margin-bottom: 10rem;
    }

    .contact-info {
      margin-bottom: 4rem;

      p,
      a {
        color: $white;
        font-size: 1.6rem;
      }

      p {
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;

        &:hover,
        &:active {
          color: #c7c7c7;
        }
      }
    }

    .social-links {
      a:not(:last-child) {
        margin-right: 3rem;
      }

      a:hover,
      a:active {
        path {
          fill: #9fbeff;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 6rem;
    padding-bottom: 6rem;

    .buy-now {
      h3 {
        font-size: 4.2rem;
      }
    }
  }
}
