.privacy-policy {
  color: var(--color-text);
  font-family: 'Barlow', sans-serif;
  font-size: 2rem;
  line-height: 3rem;
  max-width: 78%;
  padding-bottom: 12rem;
  padding-top: 5vh;
  white-space: pre-line;

  @media (max-width: 767px) {
    max-width: 100%;
    padding-top: 20vh;
  }

  h1 {
    font-family: 'Aeonik', sans-serif;
    font-size: 5.6rem;
    font-weight: 400;
    line-height: 6.7rem;

    @media (max-width: 767px) {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }
  }

  h2 {
    font-family: 'Aeonik', sans-serif;
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 3.1rem;
    padding-bottom: 0;
    padding-top: 3rem;

    @media (max-width: 767px) {
      font-size: 3rem;
      line-height: 3.5rem;
    }
  }

  h3 {
    color: #598afe;
    font-family: 'Aeonik', sans-serif;
    font-size: 2.6rem;
    font-weight: 700;
    padding-bottom: 2rem;
    padding-top: 3rem;
  }

  li {
    text-align: left;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul.dashed {
    list-style-type: none;
  }

  ul.dashed > li::before {
    content: '- ';
    text-indent: -5px;
  }

  a {
    color: #598afe;
    font-weight: 200;
    text-decoration: underline;
  }
}

.main[privacy-policy] {
  background: white;

  @media (max-width: 767px) {
    .title span a {
      color: black;
    }
  }
}
