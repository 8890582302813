#malt-banner-wrapper {
  border-radius: 0 0 30px 30px;
  width: 100%;
  height: 788px;
  overflow: hidden;
  position: relative;

  .banner-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-align: center;
    margin: 0 auto;
    max-width: 100%;

    svg {
      flex-shrink: 0;
      margin-right: 20px;
    }

    h2,
    p {
      margin: 0;
      padding: 0;
    }

    h2 {
      color: $white;
      font-family: 'Aeonik', sans-serif;
      font-size: 65px;
      font-style: normal;
      font-weight: bold;
      line-height: 78px;
      margin-bottom: 16px;
    }

    p {
      color: $white;
      font-family: 'Aeonik', sans-serif;
      font-size: 56px;
      font-style: normal;
      font-weight: normal;
      line-height: 67px;
      margin-bottom: 50px;
    }

    button {
      width: 245px;
      padding: 12px 30px;

      .btn-text {
        color: $white;
        font-family: 'Aeonik', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;

        span {
          font-weight: bold;
        }
      }
    }
  }

  .banner-background {
    height: 100%;
    position: relative;
    width: 100%;

    .banner-background-overlay {
      background-color: rgba($dark-blue, 0.4);
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    .banner-background-image {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  @media (max-width: 767px) {
    height: 568px;

    .banner-content {
      h2,
      p {
        font-size: 36px;
        line-height: 43px;
      }

      h2 {
        margin-bottom: 12px;
      }
    }
  }
}
