.situations-list {
  // stylelint-disable-next-line
  @extend .select-a;
}

.situation-title {
  color: $magenta;
  margin-bottom: 4rem;
}

.situations-list-wrapper {
  margin-top: 2%;
}

.situations-list-item {
  display: block;
  color: black;
  font-size: 2.4rem;
  padding: 9px 15px;
  line-height: 28px;
}

.situations-topic {
  margin-bottom: 2rem;

  li {
    margin-bottom: 1rem;
  }
}

.situations-topic__title {
  letter-spacing: normal;
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.topic-title {
  color: $magenta;
  font-size: 2.24rem;
  margin-bottom: 2rem;
}

.topic-content {
  font-family: Arial, Helvetica, sans-serif;
}
