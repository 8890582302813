a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.link--magenta {
  color: $magenta;

  &:hover {
    color: $magenta;
  }
}

.link--blue {
  color: $dodger-blue;
}
