#service-not-available {
  padding-top: 6rem;
  padding-bottom: 12rem;
  text-align: center;

  .sad-face {
    display: block;
    font-size: 6.6rem;
    margin-bottom: 1.5rem;
    line-height: initial;
  }

  h2,
  h3,
  .thanks-msg {
    color: #00112f;
    line-height: initial;
  }

  h2 {
    font-family: 'Aeonik', sans-serif;
    font-size: 5.6rem;
    font-weight: normal;
    margin-bottom: 5rem;
  }

  h3,
  .thanks-msg {
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
  }

  h3 {
    margin-bottom: 2rem;
  }

  #agile-monkey-link {
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      color: $blue-hover;
    }
  }

  @media (max-width: 991px) {
    h2 {
      font-size: 42px;
      margin-bottom: 3rem;
    }
  }

  @media (max-width: 767px) {
    padding-top: 12rem;
    padding-bottom: 8rem;

    br {
      content: '';

      &::after {
        content: ' ';
      }
    }

    h2 {
      font-size: 32px;
      margin-bottom: 3rem;
    }

    h3,
    .thanks-msg {
      font-size: 1.8rem;
    }
  }
}
