.pill-badge {
  background-color: $mustard;
  border-radius: 10rem;
  color: black;
  font-family: DM Sans, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.8rem;
  padding: 0.5rem 2rem;
  text-align: center;
}
