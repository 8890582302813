$black: #000;
$magenta: #fa00ff;
$pink: #f683ac;
$dodger-blue: #007bff;
$vivid-blue: #1688dc;
$royal-blue: #4263eb;
$violet-blue: #4466df;
$alice-blue: #f8f9fa;
$eerie-black: #19191b;
$hit-grey: #696871;
$purple: #7048e8;
$purple-hover: #5028c6;
$mustard: #ffe05f;
$dark-grey: #696871;
$off-grey: #acb5bd;
$burnt-orange: #ff7143;
$red-orange: #9f3919;
$darker-red-orange: #883015;
$white: #fff;
$soft-gray: #e5e5e5;
$light-blue: #5484f3;
$light-brown: #e6e4df;
$blue: #598afe;
$blue-hover: #3656dc;
$dark-blue: #00112f;
$dark-blue-hover: #304161;
$toast: #e6e4df;
$toast-hover: #979797;

:root {
  --color-page-background: #{$white};
  --color-text: #{$black};
  --color-banner-our-method: #{$mustard};
  --color-link-our-method: #{$purple};
  --color-paragraph: #{$hit-grey};
  --color-pill: transparent;
  --color-pill-border: #{$royal-blue};
}

*[darkMode] {
  --color-page-background: #{$eerie-black};
  --color-text: #{$white};
  --color-banner-our-method: #{$royal-blue};
  --color-link-our-method: #{$mustard};
  --color-paragraph: #{$off-grey};
  --color-pill: transparent;
  --color-pill-border: #{$royal-blue};
}

.informal {
  color: #f90;
}

.formal {
  color: #36f;
}

.polite {
  color: #090;
}

.background-dark-blue {
  background: #00112f;
  color: #d5d2c9;
}

.background-light-brown {
  background: $light-brown;
  color: #00112f;
}
