.btn--alt {
  background: transparent;
  border: 1px solid $magenta;
  border-radius: 0;
  color: $magenta;
  font-size: 1.4rem;
  margin-top: 4%;
  padding: 1.3rem 3.9rem;

  &.btn-secondary {
    background-color: lighten($magenta, 48%);
    border-color: lighten($magenta, 10%);
  }

  &:hover {
    background-color: $magenta;
    border-color: $magenta;
  }
}

.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: #7048e8dc radial-gradient(circle, transparent 1%, #7048e8 1%) center/15000%;
}

.ripple:active {
  background-color: #7756d8;
  background-size: 100%;
  transition: background 0s;
}

.button {
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 12px 32px;

  &--border-circle {
    border-radius: 300px;
  }

  &--border-squircle {
    border-radius: 8px;
  }

  &--primary {
    background-color: $blue;

    &:active,
    &:hover {
      background-color: $blue-hover;
    }
  }

  &--secondary {
    background-color: $dark-blue;

    &:active,
    &:hover {
      background-color: $dark-blue-hover;
    }
  }

  &--terciary {
    background-color: $toast;

    &:active,
    &:hover {
      background-color: $toast-hover;
    }
  }
}
