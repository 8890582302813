.modal-content-E4R-waiting-list {
  &__title {
    color: #fff;
    font-family: 'Barlow', sans-serif;
    font-size: 3.4rem;
    font-weight: 900;
    text-align: center;
    margin: 4rem 0;
  }

  &__description {
    color: $white;
    font-size: 2rem;
    margin-bottom: 4rem;
    text-align: center;
    line-height: 2.8rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;

    .form-input,
    .form-area,
    .form-checkbox,
    .form-select {
      width: 100%;
      margin-bottom: 2rem;
    }

    .form-checkbox {
      a {
        text-decoration: underline;
      }
    }

    p {
      margin: 0;
      color: $white;
      margin-bottom: 2rem;
    }

    button {
      color: $white;
      font-weight: 500;
      font-size: 1.9rem;
      padding: 2rem 4rem;
      margin-top: 1rem;
    }
  }

  @media (max-width: 767px) {
    &__form {
      width: 90%;
    }
  }
}
