#email-collector {
  padding-bottom: 9rem;

  @media (max-width: 991px) {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }

  #email-collector--card {
    background: rgba(246, 131, 172, 1);
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 63.25% 36.75%;
    padding-bottom: 2rem;

    @media (max-width: 991px) {
      grid-template-columns: 100%;
    }

    #email-collector--card--content {
      padding-left: 9.3rem;
      padding-top: 6.4rem;

      @media (max-width: 991px) {
        grid-row: 2;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
      }

      h3 {
        color: white;
        font-size: 6rem;
        letter-spacing: -0.103rem;
        line-height: 5.6rem;

        @media (max-width: 767px) {
          font-size: 2.8rem;
          line-height: 3.5rem;
        }
      }

      p {
        color: #fff;
        font-size: 2.4rem;
        letter-spacing: -0.075rem;
        line-height: 3.2rem;
      }

      #email-collector--card--content--subheading-2 {
        color: #19191b;
        font-family: Barlow, sans-serif;
        font-size: 2rem;
        font-weight: 500;
        line-height: 3rem;

        @media (max-width: 767px) {
          font-weight: normal;
          line-height: 2.5rem;
          padding: 0 2rem;
        }
      }

      input {
        margin-top: 1.5rem;
        width: 60%;

        @media (max-width: 767px) {
          display: block;
          margin: 0 auto;
          margin-top: 2rem;
          padding-left: 0;
          text-align: center;
          width: 85%;
        }

        &::placeholder {
          color: #acb5bd;
        }
      }

      button {
        border: 1px solid #7048e8;
        margin: 0;
        margin-left: 5%;
        width: 20%;

        @media (max-width: 767px) {
          display: block;
          margin: 0 auto;
          margin-top: 2rem;
          width: 85%;
        }
      }
    }

    #email-collector--card--image {
      @media (max-width: 767px) {
        grid-row: 1;
        padding-top: 1rem;
      }

      img {
        max-width: 350px;

        @media (max-width: 767px) {
          max-width: 192px;
        }
      }
    }
  }
}
