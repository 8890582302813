.content-description {
  padding-top: 8rem;
}

.cta-shop {
  display: flex;
  justify-content: center;
  padding-bottom: 6rem;
}

.content-description--title,
.plan-picker--title {
  text-align: center;

  h3 {
    margin-bottom: 0;
    padding-bottom: 2rem;

    @media (max-width: 767px) {
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  p {
    margin: 0 auto;
    width: 50%;

    @media (max-width: 767px) {
      width: 85%;
    }
  }
}

.content-description--wrapper {
  padding-bottom: 5.75rem;
}

.content-description--feature {
  background: rgba(66, 99, 235, 0.1);
  border-radius: 2.1rem;
  display: grid;
  grid-template-columns: 11.6% 58.75% 26.65%;
  margin: 3rem 0;
  mix-blend-mode: normal;
  padding: 4rem 4.5rem;

  @media (min-width: 768px) and (max-width: 991px) {
    grid-gap: 5%;
    grid-template-columns: 12% 48% 30%;
    padding: 4rem 2.5rem;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    text-align: center;

    > div:first-child {
      display: flex;
      justify-content: center;
    }
  }

  .content-description--feature-content {
    padding-right: 2rem;

    @media (max-width: 767px) {
      padding-right: 0;

      p {
        margin-bottom: 3rem;
      }
    }
  }

  h4 {
    margin-bottom: 2rem;
  }

  .content-description--feature--quantity-wrapper {
    align-items: center;
    display: flex;
  }

  .content-description--feature--quantity {
    background-color: var(--color-pill);
    border: 1px solid var(--color-pill-border);
    border-radius: 3.3rem;
    color: var(--color-pill-border);
    display: inline-flex;
    font-size: 1.6rem;
    justify-content: space-around;
    padding: 1.8rem 4.8rem;
    width: 100%;

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 1.8rem;
    }

    div {
      padding: 5px 0;
    }

    .border-blue {
      border-right: 1px solid var(--color-pill-border);
    }
  }
}
