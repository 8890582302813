.login {
  .info {
    color: $vivid-blue;
    font-size: 1.8rem;
    line-height: 28px;
    margin-bottom: 6rem;
  }

  .btn {
    margin-bottom: 4%;
  }
}

.login-links {
  display: inherit;
  flex-direction: column;
}

.row {
  margin-bottom: 2rem;
  margin-left: 0;
}
