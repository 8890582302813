#malt-clients-wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10rem 0;

  .section-title {
    color: $dark-blue;
    font-family: 'Aeonik', sans-serif;
    font-size: 26px;
    font-style: italic;
    font-weight: normal;
    line-height: 31px;
    margin-bottom: 40px;
  }

  .malt-clients {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .client {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: 6rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1187px) and (min-width: 767px) {
    .malt-clients {
      .client {
        flex-basis: calc(50% - 100px);

        // this is because safari doesn't support gap
        margin: 0;
        margin-bottom: 6rem;
        margin-right: 6rem;

        &:nth-child(2),
        &:nth-child(4) {
          margin-right: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-title {
      font-size: 20px;
      line-height: 24px;
    }

    .malt-clients {
      flex-direction: column;
      // this is because safari doesn't support gap

      .client {
        flex-basis: 1;
        height: 100px;
        margin: 0;
        margin-bottom: 3rem;

        &:last-child {
          margin-bottom: 0;
        }

        svg,
        image {
          max-height: 120px;
        }
      }
    }
  }
}
