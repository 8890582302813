.plan-picker {
  padding-bottom: 5.5rem;
  padding-top: 10rem;

  @media (max-width: 767px) {
    padding-bottom: 3rem;
    padding-top: 6rem;
  }

  .plan-picker--title {
    margin: 0 auto;
    text-align: center;
    width: 72%;

    @media (max-width: 991px) {
      width: 100%;
    }

    h3 {
      font-size: 4.6rem;
      font-weight: 200;
      line-height: 6.6rem;
      padding-bottom: 4rem;

      @media (max-width: 767px) {
        font-size: 3.4rem;
        line-height: 5rem;
      }
    }

    .plan-picker--subtitle {
      font-size: 2.4rem;
      padding-bottom: 4rem;
      width: 100%;

      @media (max-width: 767px) {
        font-size: 1.8rem;
      }
    }
  }
}

.pricing-options {
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  max-width: 110rem;
  padding: 0 $m-size;

  @media (min-width: 768px) and (max-width: 991px) {
    grid-gap: unset;
    grid-template-columns: 1fr;
    margin: auto;
    width: 90%;
  }

  @media (max-width: 767px) {
    grid-gap: unset;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: max-content;
    padding: 0;
  }
}

.pricing-card {
  background-color: $dark-blue;
  border-radius: 3rem;
  color: white;
  margin: 1.6rem 0;
  text-align: center;
  padding-bottom: 2rem;
  box-shadow: 0 13px 18px rgba(0, 0, 0, 0.15), 0 11px 55px rgba(0, 0, 0, 0.0001);

  .pricing-card--button {
    margin-left: auto;
    margin-right: auto;
    color: $white;
    font-family: 'Aeonik', sans-serif;
    font-weight: 500;
    font-size: 22px;
  }

  @media (max-width: 767px) {
    box-shadow: none;
    padding-bottom: 8rem;
    margin-bottom: 0;

    .pricing-card--button {
      font-size: 18px;
    }
  }

  .pricing-card--title {
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    background: #30405a;
    text-align: center;
    padding: 2.7rem 2.6%;
    line-height: 4.6rem;
    letter-spacing: 0.04rem;
    font-weight: 200;
  }

  .pricing-card-features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 2.5rem;
    margin: 4rem auto;
    text-align: left;
    width: 89%;

    @media (max-width: 767px) {
      margin: 5rem auto;
      width: 90%;
    }

    span {
      background: #30405a;
      border-radius: 4rem;
      display: inline-block;
      font-size: 1.8rem;
      line-height: 2rem;
      margin-bottom: 1rem;
      margin-right: 1rem;
      padding: 2rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 767px) {
        line-height: 2.1rem;
        margin-right: 0;
        border-radius: 6rem;
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }

    .large {
      @media (max-width: 767px) {
        max-width: 90%;
      }
    }

    .medium {
      @media (max-width: 767px) {
        max-width: 80%;
      }
    }

    .break {
      margin-left: 10%;
      margin-right: 10%;
    }

    .green {
      color: #a6c487;
    }

    .white {
      color: #fff;
    }

    .purple {
      color: #9e91dc;
    }

    .red {
      color: #f1827f;
    }

    .orange {
      color: #d69d74;
    }

    .blue {
      color: #9ac4df;
    }

    @media (max-width: 767px) {
      font-size: 1.9rem;
      line-height: 2.5rem;
    }
  }
}

.pricing-contact {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 auto;
  padding: 6rem 0;
  width: 72%;

  @media (min-width: 768px) and (max-width: 991px) {
    width: 85%;
  }

  p {
    font-weight: 200;
  }

  a {
    color: $light-blue;
    font-size: 2rem;
    font-weight: 500;

    &:hover {
      color: #00112f;
      text-decoration: none;
    }
  }

  h4 {
    font-weight: 500;
  }

  @media (max-width: 767px) {
    grid-gap: 10%;
    grid-template-columns: 100%;
    width: 100%;
  }

  div {
    @media (max-width: 767px) {
      padding: 0;
    }

    &:first-child {
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border-right: 1px solid rgba(43, 41, 45, 0.2);
      padding-left: 5.2%;
      padding-right: 19.7%;

      @media (max-width: 767px) {
        border-bottom: 1px solid rgba(43, 41, 45, 0.2);
        border-right: none;
        padding-bottom: 4rem;
      }
    }
  }

  #business {
    padding-left: 13%;
    padding-right: 5.2%;

    @media (max-width: 767px) {
      padding-left: 5.2%;
      padding-right: 10%;
    }
  }
}
