#cookie-banner {
  .toast {
    background-color: #f8f9fa;
    border-radius: 10px;
    bottom: 10px;
    height: 90px;
    max-width: none;
    position: fixed;
    right: 10px;
    width: 500px;
    z-index: 10000;

    @media (max-width: 767px) {
      bottom: 10px;
      height: auto;
      left: 0;
      margin: 0 auto;
      right: 0;
      width: 75vw;
    }

    .toast-body {
      padding: 0;
    }

    .row {
      margin-bottom: 0;
      margin-right: 0;
    }

    p {
      font-family: 'roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.4rem;
      text-align: left;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    strong {
      color: $black;
      font-family: 'roboto', sans-serif;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.4rem;
    }

    #cookie-icon-column {
      align-items: center;
      display: flex;
      padding-top: 0;

      #cookie-icon {
        padding-left: 1rem;
        width: 60px;

        @media (max-width: 767px) {
          padding-left: 0;
          width: 50px;
        }
      }
    }

    #text-column {
      padding-right: 2rem;
      padding-top: 1.5rem;

      @media (max-width: 767px) {
        padding-right: 0;
      }
    }

    #cross-icon-column {
      padding-top: 1.5rem;

      #cross-icon {
        cursor: pointer;
        height: 15px;
        width: 15px;
      }
    }
  }
}
