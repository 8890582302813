.language-selector {
  button {
    font-family: 'Aeonik', sans-serif;
    font-size: 1.8rem;
    font-weight: 200;
  }

  .dropdown-toggle {
    border-radius: unset;
  }

  .dropdown-toggle::after {
    margin-left: 1.5rem;
  }

  .dropdown-menu {
    background-color: transparent;
    border: none;
  }
  //reset bootstrap styles
  button,
  button:hover,
  button:focus,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle,
  .show > .btn-success.dropdown-toggle:focus,
  .btn-success:not(:disabled):not(.disabled):active:focus {
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: var(--color-text);
    padding: unset;
  }

  .dropdown-item {
    background: $light-brown;
    border: 0.5px solid #000;
    border-radius: 8px;
    font-size: 1.8rem;
    line-height: 2.6rem;
    margin: 1rem 0;
    padding: 1rem 4rem;
    text-align: center;

    &.active,
    &:active {
      color: var(--color-text);
      font-weight: 500;
    }

    &:hover {
      background-color: #d5d2c9;
      color: initial;
      padding: 1rem 4rem;
      text-decoration: none;
    }
  }
}
