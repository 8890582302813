.presentation {
  &.big-content-container {
    padding: 8rem 0;

    @media (max-width: 767px) {
      background-image: url('../assets/rocket.gif');
      background-size: cover;
      padding: 20vh 2.2rem 10vh;
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 4rem;
  }

  p {
    text-align: center;
    margin-bottom: 0;

    @media (max-width: 767px) {
      color: white;
      font-weight: 500;
      text-shadow: 2px 0 10px rgba(0, 0, 0, 1);
    }
  }

  .bg-wrapper {
    display: grid;
    grid-gap: 2%;
    grid-template-columns: 72% 26%;
    margin: 0 auto;
    padding-bottom: 8rem;
    width: 82.9%;

    @media (max-width: 767px) {
      display: block;
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      grid-template-columns: 100%;
    }

    .title-pills {
      font-weight: 200;
      text-align: right;

      @media (max-width: 767px) {
        text-align: center;
      }

      span {
        background-color: white;
        border-radius: 4rem;
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -0.01rem;
        margin-bottom: 1rem;
        padding: 2.5rem 6rem;

        @media (max-width: 767px) {
          font-size: 2.4rem;
          padding: 2rem;
        }
      }

      div {
        text-align: left;

        @media (max-width: 767px) {
          text-align: center;
        }

        span {
          background: #ffb27a;
        }
      }
    }

    p {
      font-size: 2.1rem;
      margin-bottom: 3%;

      @media (min-width: 992px) {
        margin: 0 auto;
        max-width: 80%;
        padding-top: 2rem;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        padding-top: 2rem;
      }

      @media (max-width: 767px) {
        max-width: 100%;
      }

      @media (max-width: 991px) {
        padding-bottom: 2rem;
      }
    }

    #rocket-gif {
      border-radius: 100%;
      height: 264px;
      width: 264px;
    }
  }

  .title {
    font-weight: 400;

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

#arrow {
  margin-bottom: -30px;

  @media (max-width: 767px) {
    margin-top: -115px;
  }
}
