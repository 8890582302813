@import url('https://fonts.googleapis.com/css?family=Raleway:200,300,400,600,700');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
  font-family: 'Aeonik';
  font-weight: 400;
  src: local('Aeonik'), url(../assets/fonts/Aeonik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 200, normal;
  src: local('Aeonik'), url(../assets/fonts/Aeonik-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 500;
  src: local('Aeonik'), url(../assets/fonts/Aeonik-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Aeonik';
  font-weight: 700;
  src: local('Aeonik'), url(../assets/fonts/Aeonik-Bold.ttf) format('truetype');
}

.bold {
  font-weight: 700;
}

.x-bold {
  font-weight: 900;
}

.underline {
  text-decoration: underline;
}

.magenta {
  color: $magenta;
}
