.landing-header {
  padding-bottom: 0;
  width: 100%;
  z-index: 2;

  @media (max-width: 767px) {
    position: absolute;
  }

  .header-nav {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline;
        font-family: 'Aeonik', sans-serif;
        font-size: 1.7rem;
        font-weight: 700;
        margin: 2rem;
        padding-bottom: 1rem;

        &:last-child {
          margin-right: 0;
        }

        a {
          color: var(--color-text);
          font-size: inherit;
          text-decoration: none;
        }
      }

      li.active {
        border-bottom: 1px solid $purple;
      }

      li.hide {
        display: none;
      }
    }
  }

  .header-flex-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.landing-header[white-bg] {
  .title * {
    color: #00112f;
  }

  path {
    fill: #00112f;
  }
}

.malt-page {
  header {
    left: 0;
    position: fixed;
    z-index: 100;

    &.--scrolled {
      background-color: #001847;
    }

    .title span a {
      color: $white;
    }

    .header-links {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .section-links {
        display: flex;
        align-items: center;

        a {
          color: $white;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          text-decoration: none;
          cursor: pointer;
          margin-right: 56px;

          &.active {
            font-weight: bold;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .language-selector {
        .dropdown-toggle {
          color: $white;

          &:hover,
          &:focus {
            color: $white !important;
          }
        }

        .dropdown-menu {
          padding: 0 10px;
          border-radius: 8px;
          margin-top: 20px !important;

          button {
            box-shadow: 0 0 0 0.5px $dark-blue;
            border-radius: 8px;
            border: 0;
            color: $dark-blue;
            background-color: $white;

            svg {
              margin-left: 0.5rem;

              path {
                fill: $dark-blue;
              }
            }

            &.active {
              color: $white;
              background-color: $blue;
              box-shadow: 0 0 0 0.5px $blue;

              svg {
                margin-left: 0.5rem;

                path {
                  fill: $white;
                }
              }
            }

            &:hover {
              color: $white;
              background-color: $dark-blue;
              box-shadow: 0 0 0 0.5px $dark-blue;
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .title {
        a {
          font-size: 2.6rem;
        }
      }
    }

    @media (max-width: 767px) {
      .header-links {
        .title span a {
          color: $white;
        }

        .header-flex-wrapper {
          justify-content: center;
        }
      }
    }
  }
}
