.warranty {
  display: flex;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 5%;

  @media (max-width: 767px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  img {
    height: auto;
    width: 50%;

    @media (max-width: 767px) {
      width: 150%;
    }
  }

  .warranty-img {
    @media (max-width: 767px) {
      padding-right: 1rem;
    }
  }

  .warranty-content {
    margin-left: -2%;
    white-space: pre-wrap;

    @media (max-width: 767px) {
      white-space: normal;
    }

    a {
      color: $royal-blue;
      font-family: inherit;
      font-size: inherit;
      font-weight: 400;
    }

    @media (max-width: 767px) {
      margin-left: 3%;
    }
  }
}
