#promobar-content-E4R {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    width: fit-content;
    margin: 0;
    font-family: 'Aeonik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: $dark-blue;

    a {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  img {
    display: block;
    height: 40px;
    width: 50px;
    margin-right: 12px;
    border-radius: 5px;
  }

  @media (max-width: 767px) {
    p {
      font-size: 16px;
      line-height: 20px;
      max-width: 80%;
    }

    img {
      position: absolute;
      left: 20px;
    }
  }
}
