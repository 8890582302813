#companies-info-section {
  background-color: $violet-blue;
  color: white;
  padding-top: 5rem;

  .pill-badge {
    @media (max-width: 767px) {
      display: block;
      margin: 0 auto;
      width: 25%;
    }
  }

  h3 {
    margin: 0;
    padding-top: 2rem;

    @media (max-width: 767px) {
      padding-bottom: 2rem;
      text-align: center;
    }
  }

  #contact-for-more-information,
  #contact-for-more-information--form {
    color: $mustard;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 3.6rem;
  }

  #contact-for-more-information--form {
    font-family: DM Sans, sans-serif;
    font-weight: normal;
    letter-spacing: -0.075rem;
    text-align: center;
  }

  #companies-info-grid {
    display: grid;
    grid-gap: 5%;
    grid-template-columns: 55% 40%;
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
      grid-gap: 0;
      grid-template-columns: 100%;
    }
  }

  #companies-info__content {
    @media (max-width: 767px) {
      grid-row: 2;
      padding-bottom: 4rem;
    }
  }

  #companies-info__info-list {
    margin-top: 2.6rem;
  }

  .companies-info__info-item {
    display: flex;
    font-size: 2rem;
    line-height: 3rem;
    padding-bottom: 2.4rem;

    span {
      padding-left: 1rem;
    }
  }

  #companies-info__form-wrapper {
    @media (max-width: 767px) {
      grid-row: 1;
    }
  }

  form {
    @media (max-width: 767px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    input,
    button {
      margin-bottom: 2.2rem;
      width: 80%;

      @media (max-width: 767px) {
        width: 90%;
      }
    }

    .successCard {
      background: #8bc34a;
      border: 1px solid white;
      border-radius: 1rem;
      font-family: DM Sans, sans-serif;
      font-size: 2rem;
      letter-spacing: -0.02rem;
      line-height: 2.6rem;
      margin-bottom: 3.4rem;
      opacity: 1;
      padding: 2rem 10%;
      text-align: center;
      transition: opacity 0.3s ease-in;
      white-space: pre-wrap;
      width: 80%;

      @media (max-width: 767px) {
        width: 90%;
      }

      svg {
        margin-bottom: 1rem;
      }
    }

    button {
      background: $pink;
      margin-bottom: 9.2rem;
      margin-top: 0;
      transition: opacity 0.3s ease-out;

      @media (max-width: 767px) {
        margin-bottom: 2rem;
      }
    }
  }

  img {
    bottom: 0;
    max-height: 660px;
    position: absolute;
    z-index: -1;
  }

  .disappeared {
    opacity: 0;
    position: fixed;
    visibility: hidden;
  }
}
