.mobile-hide {
  @media (max-width: 767px) {
    display: none;
  }
}

.tablet-hide {
  @media (min-width: 768px) and (max-width: 991px) {
    display: none;
  }
}

.desktop-hide {
  @media (min-width: 992px) {
    display: none;
  }
}
