#footer {
  background-color: #00112f;
  border-radius: 3rem 3rem 0 0;
  color: white;
  font-family: 'Aeonik', sans-serif;
  font-size: 1.7rem;
  line-height: 2rem;
  padding-top: 7.5rem;

  .footer--link.subtitle {
    color: #5c8ffe;
  }

  .footer-grid {
    display: grid;
    grid-template-columns: 50% 50%;

    .links {
      margin-top: 4.6rem;
      margin-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 991px) {
        margin-top: 3rem;
      }

      a {
        border-radius: 8px;
        margin-bottom: 1.5rem;
        margin-right: 1rem;
        padding: 1.5rem 2.4rem;
      }
    }

    .title {
      margin-bottom: 3.5rem;
      padding: 0;
      text-align: left;

      @media (max-width: 991px) {
        margin-left: 3%;
      }

      span {
        line-height: inherit;

        #title-footer {
          color: $white;
        }
      }
    }

    .subtitle {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin-bottom: 2rem;
      width: 60%;

      @media (max-width: 991px) {
        line-height: 2.9rem;
      }
    }

    @media (max-width: 991px) {
      grid-template-columns: 100%;

      div {
        margin-bottom: 2.5%;
      }
    }
  }

  .footer--links {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 6rem 0;

    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      padding-top: 6rem;
    }

    @media (max-width: 991px) {
      padding: 3rem 0;
    }

    #footer--link-copyright {
      color: #979797;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-left: 3%;
    }

    #footer--link-privacy-policy {
      text-decoration: underline;

      @media (max-width: 991px) {
        margin-bottom: 5%;
        margin-left: 3%;
      }
    }
  }

  .footer--contact-us {
    margin-left: 3%;

    .links a {
      font-weight: 500;
      color: $white;
      background-color: $blue;
    }

    .links a:hover {
      background-color: $blue-hover;
      text-decoration: none;
    }

    h2 {
      font-size: 2.2rem;
      line-height: 2.8rem;
    }

    .contact-us--button {
      align-items: center;
      display: inline-flex;
      line-height: 1.5rem;

      svg {
        margin-right: 1rem;

        path {
          fill: $white;
        }
      }
    }

    .footer--social {
      a svg {
        margin-right: 5%;
        margin-top: 2.6rem;

        &#tiktok-icon {
          margin-left: 1%;
        }

        &:hover {
          path {
            fill: #9ec8ff;
          }
        }
      }
    }
  }

  #agile-monkey-link {
    text-decoration: underline;
  }

  #agile-monkey-link:hover {
    color: #3656dc;
  }
}
