.content-container {
  margin: 0 auto;
  max-width: 80rem;
  padding: 0 $m-size;
  text-align: left;
}

.big-content-container {
  margin: 0 auto;
  max-width: 110rem;
  padding: 0 $m-size;
  text-align: left;

  @media (max-width: 767px) {
    padding: 0 2.2rem;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 3.6rem;
  }
}
