.learn-more {
  background-color: var(--color-banner-our-method);

  .learn-more-img {
    @media (max-width: 767px) {
      padding-bottom: 3rem;
      padding-top: 3rem;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  p {
    color: white;
    margin-bottom: 4rem;
    max-width: 80%;

    @media (max-width: 767px) {
      font-size: 1.8rem;
      line-height: 2.8rem;
      margin: 0 auto;
      margin-bottom: 4rem;
    }
  }

  a,
  a:not([href]):not([class]) {
    color: var(--color-link-our-method);
    padding-top: 4rem;
    text-decoration: underline;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  .feature {
    display: flex;
    padding: 1rem 0;

    @media (max-width: 767px) {
      text-align: left;
    }

    svg {
      color: white;
    }

    span {
      color: var(--color-paragraph);
      font-size: 2rem;
      padding-left: 1rem;

      @media (max-width: 767px) {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
  }
}

.learn-more-grid {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 6rem 0;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding-top: 0;
    text-align: center;
  }

  img {
    padding-right: 5rem;
    width: 100%;

    @media (max-width: 767px) {
      padding-right: 0;
    }
  }
}

.learn-more-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
