.header {
  background: $alice-blue;
  font-size: 1.6rem;

  .btn,
  .form-control {
    font-size: 1.6rem;
  }
}

.navbar-light .navbar-brand {
  color: $magenta;
  font-size: 1.6rem;
}

@media screen and (max-width: 990px) {
  .header {
    align-items: center;
    display: flex;
    height: 10vh;
    justify-content: space-between;
    position: fixed;
    width: 100%;

    .content-container {
      width: 100%;
    }
  }
}
