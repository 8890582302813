.title {
  padding: 2vh 0;

  @media (max-width: 767px) {
    text-align: center;
  }

  span {
    color: var(--color-text);
    font-family: 'Barlow', sans-serif;
    font-size: 3rem;
    font-weight: 900;
    line-height: 7.2rem;

    a {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-decoration: none;

      @media (max-width: 767px) {
        color: white;
      }
    }
  }
}
