#malt-know-more {
  max-width: 65%;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 10rem;

  @media (max-width: 767px) {
    max-width: 85%;
  }

  h2 {
    font-size: 4.6rem;
    line-height: 5.5rem;
    font-weight: normal;
    margin-bottom: 2rem;

    @media (max-width: 767px) {
      font-size: 2.6rem;
      line-height: 3.1rem;
    }
  }

  a {
    text-decoration: none;
  }

  p {
    font-size: 2.2rem;
    line-height: 3rem;
    margin: 3rem auto;
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  .button {
    margin: 3rem auto;
    color: $white;
    display: flex;
    font-family: 'Aeonik', sans-serif;
    align-items: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;

    svg {
      margin-right: 10px;
    }
  }

  #not-developer {
    font-size: 1.8rem;
    line-height: 3rem;

    #btn-not-a-developer--bottom {
      text-decoration: underline;
      color: $blue;
      font-weight: bold;
      cursor: pointer;
    }

    @media (max-width: 767px) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}
