.footer {
  background-color: $alice-blue;
  padding: 4rem 0;
  width: 100%;
}

.footer__navigation {
  text-align: center;
}

.footer__copyright {
  margin: 0;
  padding-top: 2rem;
  text-align: center;
}
