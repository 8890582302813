.promobar-wrapper {
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .promobar {
    padding: 0 20px;
    height: 100%;
    width: 100%;
    margin: 0;
    border: 0;
    position: relative;

    &__content {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
    }

    &__close-btn {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%);
      height: 15px;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
