.malt-help {
  p {
    color: $dark-blue;
    font-family: 'Aeonik', sans-serif;
    font-size: 46px;
    font-style: normal;
    font-weight: normal;
    line-height: 55px;
    margin-bottom: 43px;
    text-align: center;

    b {
      display: block;
      font-weight: bold;
      margin-top: 10px;
    }
  }

  .btns {
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 16px;

    button {
      height: 48px;

      svg {
        margin-right: 6px;
      }

      .button__text {
        color: $dark-blue;
        font-family: 'Aeonik', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 13px;
      }
    }
  }

  @media (max-width: 767px) {
    p {
      font-size: 26px;
      line-height: 31px;
    }
  }
}
