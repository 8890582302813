.form-error {
  background-color: #f1817f;
  border-radius: 5px;
  color: #fff;
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: fit-content;
  font-weight: 500;
}

.form-input {
  border: 0;
  border-radius: 5px;
  color: #00112f;
  font-family: 'Aeonik', sans-serif;
  font-weight: 500;
  font-size: 2.1rem;
  padding: 1.9rem 2.4rem 1.6rem 2.4rem;
  height: auto;
  outline: 0;

  &::placeholder {
    color: #979797;
  }

  &:focus {
    box-shadow: 0 0 0 3px $blue-hover;
    color: #00112f;
  }

  &.--error {
    box-shadow: 0 0 0 3px #f1817f;
  }

  @media (max-width: 767px) {
    font-size: 1.8rem;
    padding: 2rem;
  }
}

.form-area {
  @extend .form-input;

  resize: none;
}

.form-checkbox {
  position: relative;
  cursor: pointer;
  display: block;
  padding-left: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: fit-content;
  color: $white;
  font-weight: 500;

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }

  &__checkmark {
    background-color: #fff;
    border-radius: 2px;
    height: 14px;
    left: 0;
    position: absolute;
    top: calc(50% - 8px);
    width: 14px;
  }

  &:hover input ~ &__checkmark {
    background-color: #e6e4df;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ &__checkmark {
    background-color: #598afe;
    border: 1px solid #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &__checkmark::after {
    content: '';
    display: none;
    position: absolute;

    /* Style the checkmark/indicator */

    border: solid white;
    border-width: 0 2px 2px 0;
    height: 8px;
    left: 4px;
    top: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 4px;
  }

  /* Show the checkmark when checked */
  input:checked ~ &__checkmark::after {
    display: block;
  }
}

.form-select {
  @extend .form-input;

  padding: 0;

  @media (max-width: 767px) {
    * {
      font-size: 1.8rem !important;
    }
  }
}
