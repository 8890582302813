.description-section-wrapper {
  padding: 80px 0;
  background-color: #e0e0e0;

  .description-section {
    text-align: center;

    h3 {
      font-size: 6.5rem;
      margin-bottom: 40px;
    }

    .step-section-link {
      position: relative;
      text-align: left;
      height: 40px;
    }

    .step-section-link::after {
      content: '';
      position: absolute;
      z-index: 1;
      left: 79px;
      height: 100%;
      border-left: 2px #00112f dotted;
      overflow: hidden;
    }

    .step-section {
      width: 100%;
      max-width: 678px;
      margin: 0 auto;
    }

    .step-section-header {
      position: relative;
      background-color: #6e798b;
      color: #fff;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: -10px;

      h4 {
        font-size: 1.6rem;
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    .step-section-description {
      position: relative;
      background-color: #fff;
      padding: 3rem 10px 0 40px;
      border-radius: 0 0 10px 10px;
      overflow: hidden;

      ul.steps {
        list-style: none;
        text-align: left;
        margin: 0;

        & > li {
          position: relative;
          padding: 0 0 20px 40px;
          font-size: 2rem;
          font-weight: 700;

          &.completed {
            color: #00112f80;
          }

          &::after {
            content: '';
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            transform: translateX(-50%) translateY(50%);
            border-radius: 50%;
            background: #00112f;
            border: 2px #00112f solid;
            width: 16px;
            height: 16px;
          }

          &.active::after {
            background: #598afe;
          }

          &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 8px;
            left: -1px;
            height: 100%;
            border-left: 2px #00112f solid;
            overflow: hidden;
          }

          &.first::before {
            top: -80px;
            height: 160px;
          }

          .last {
            padding-bottom: 3rem;

            &::before {
              height: 100%;
            }
          }

          &.none::before {
            height: 0;
          }

          &.first-and-last {
            margin-bottom: 2.5rem;

            &::before {
              top: -80px;
              height: 100px;
            }
          }

          span.active {
            position: relative;
            top: -2px;
            background-color: #f1817f;
            font-size: 1.5rem;
            color: #fff;
            text-transform: uppercase;
            padding: 3px 10px;
            margin-left: 10px;
            border-radius: 10px;
          }

          i {
            position: relative;
            top: -2px;
            margin-left: 20px;
          }

          ul.sub-steps {
            list-style: '▶';
            margin-top: 2rem;
            padding: 0;
            font-weight: 500;

            li {
              padding-left: 2rem;
              margin-bottom: 2rem;
              color: #6e798b;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .description-section {
      h3 {
        font-size: 4.2rem;
        padding: 0;
        margin-bottom: 5rem;
      }

      .step-section {
        .step-section-link::after {
          left: 3.4rem;
        }

        .step-section-description {
          padding-left: 3.5rem;
          padding-right: 3.5rem;
          padding-top: 2.5rem;

          ul.steps {
            padding: 0;

            li {
              i {
                display: none;
              }

              span.active {
                display: block;
                width: fit-content;
                margin-left: 0;
                margin-top: 1rem;
              }

              &.last {
                padding-bottom: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
}
