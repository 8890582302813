.custom-modal {
  height: 99%;

  .modal-dialog {
    max-width: 667px;
    padding: 1rem;

    &.modal-lg {
      height: 100%;
      margin: auto;
      display: grid;
      align-items: center;
      justify-items: center;
    }

    .modal-content {
      background-color: transparent;
      border: none;
      max-width: 800px;

      .modal-body {
        background: #598afe;
        border-radius: 1rem;
        font-family: 'Aeonik', sans-serif;
        margin: auto;
        padding: 3rem;
        width: 100%;

        &__close-btn {
          height: 20px;
          width: 20px;
          margin-left: auto;

          svg {
            cursor: pointer;

            path {
              fill: $white;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .modal-dialog {
      max-width: 500px;
    }
  }
}
