#malt-how-club-works {
  background: #ebecef;
  border-radius: 3rem;
  color: #00112f;
  padding: 3.5rem 10px;
  margin: 5rem 0;

  h2 {
    font-size: 4.6rem;
    line-height: 5.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 767px) {
      font-size: 3.2rem;
      line-height: 4.4rem;
    }
  }

  p {
    font-size: 2.2rem;
    line-height: 3rem;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  #malt-how-club-works__video-wrapper {
    margin: 3rem auto;

    iframe {
      width: 100%;
      height: 419px;
      max-width: 749px;
      max-height: 419px;
    }

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;

      iframe {
        max-height: 225px;
      }
    }
  }

  small {
    color: #6e798b;
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.08em;

    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
