#malt-bullets-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10rem;
  margin-bottom: 6rem;
  text-align: center;

  p {
    color: $dark-blue;
    font-family: 'Aeonik', sans-serif;
    margin: 0;
  }

  .section-title {
    font-size: 46px;
    font-style: normal;
    font-weight: normal;
    line-height: 55px;
    margin-bottom: 15px;
  }

  .section-description {
    font-size: 26px;
    font-style: normal;
    font-weight: normal;
    line-height: 31px;
  }

  .section-clip-hanger {
    font-size: 26px;
    font-style: italic;
    font-weight: normal;
    line-height: 31px;
  }

  .malt-bullets {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0;
    padding: 0 10%;

    .bullet {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-right: 6rem;

      &:last-child {
        margin-right: 0;
      }

      &__icon {
        align-items: center;
        background-color: #ebecef;
        border-radius: 50%;
        display: flex;
        height: 120px;
        justify-content: center;
        width: 120px;
      }

      &__text {
        margin-top: 2.3rem;
        color: $dark-blue;
        font-family: 'Aeonik', sans-serif;
        font-size: 26px;
        font-style: normal;
        font-weight: bold;
        line-height: 31px;
        width: 165px;
      }
    }
  }

  @media (max-width: 965px) and (min-width: 767px) {
    .malt-bullets {
      .bullet {
        margin: 0;
        margin-bottom: 60px;
        margin-right: 30px;
        flex-basis: calc(50% - 36px);

        &:nth-child(2),
        &:nth-child(4) {
          margin-right: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 5rem;

    .section-title {
      font-size: 26px;
      font-weight: bold;
      line-height: 31px;
      margin-bottom: 24px;
    }

    .section-description {
      font-size: 20px;
      line-height: 24px;
    }

    .section-clip-hanger {
      font-size: 20px;
      line-height: 24px;
    }

    .malt-bullets {
      flex-direction: column;
      margin: 32px 0;

      .bullet {
        margin-bottom: 1.5rem;
        flex-direction: row;

        &__icon {
          flex-shrink: 0;
          height: 95px;
          width: 95px;

          svg {
            height: 48%;
          }
        }

        &__text {
          margin-top: 0;
          margin-left: 3rem;
          font-size: 20px;
          font-style: normal;
          font-weight: bold;
          line-height: 24px;
          text-align: left;
          width: auto;
          word-wrap: break-word;
        }
      }
    }
  }
}
