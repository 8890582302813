#malt-hamburger-menu {
  .modal-content {
    max-width: 85vw;
    height: 90vh;
    margin-left: auto;
    background-color: $white;
    margin-top: 30px;
    margin-right: -10px;
    border-radius: 30px 0 0 30px;
    padding-left: 5rem;
    padding-right: 4rem;
    padding-top: 3rem;
    padding-bottom: 8rem;

    .modal-body {
      display: flex;
      flex-direction: column;

      .hamburger-title {
        margin-bottom: 4rem;

        .close-btn {
          margin-left: auto;
          height: 20px;
          width: 20px;

          svg > path {
            fill: #9aa1ae;
          }
        }
      }

      .hamburger-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex: 1;

        .links {
          display: flex;
          flex-direction: column;

          a {
            font-family: 'Aeonik', sans-serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: underline;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 40px;

            &.active {
              color: $blue;
              font-weight: bold;
              text-decoration: none;
            }
          }
        }

        .language-zone {
          margin-top: auto;

          &__title {
            font-family: 'Aeonik', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            color: #9aa1ae;
            margin-bottom: 2rem;
          }

          &__list {
            a,
            span {
              font-family: 'Aeonik', sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 22px;
              line-height: 26px;
            }

            .separator {
              margin: 0 1rem;
            }

            a {
              text-decoration: underline;

              &.active {
                font-weight: bold;
                color: $blue;
                text-decoration: none;
              }
            }

            &:first-child {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}
