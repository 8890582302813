.not-found-page {
  padding: 10vh 0 12rem;

  @media (max-width: 767px) {
    padding-top: 20vh;
  }

  .not-found-grid {
    display: grid;
    grid-gap: 10%;
    grid-template-columns: 40% 40%;
    width: 90%;

    @media (max-width: 991px) {
      grid-template-columns: 80%;

      #not-found-content {
        grid-row: 2;
      }

      #not-found-image {
        grid-row: 1;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 100%;
    }

    img {
      height: 100%;
      width: 100%;
    }

    #title,
    #subtitle {
      display: block;
    }

    #title {
      color: $eerie-black;
      font-size: 5.6rem;
      line-height: 6.7rem;
      margin-bottom: 2.4rem;
    }

    #subtitle {
      font-size: 2.6rem;
      line-height: 3.2rem;
      margin-bottom: 4.3rem;
    }

    button {
      margin: 1rem 0;
      padding: 2rem 4rem;

      a {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 2.6rem;
        text-decoration: none;
      }
    }
  }
}

.main[not-found] {
  background: white;

  .title span a {
    color: black;
  }
}
