/* stylelint-disable no-descending-specificity */
#malt-offer-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;

  .malt-offer {
    background-color: #fff873;
    border-radius: 30px;
    flex-grow: 1;
    max-width: 1800px;
    padding: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__catch-phrase {
      color: $dark-blue;
      font-family: 'Aeonik', sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: bold;
      line-height: 31px;
      margin-bottom: 20px;
    }

    &__title {
      align-items: center;
      color: $dark-blue;
      display: flex;
      font-family: 'Aeonik', sans-serif;
      font-size: 56px;
      font-style: normal;
      font-weight: normal;
      line-height: 67px;
      margin-bottom: 23px;

      svg {
        margin-right: 2rem;
      }
    }

    &__description {
      color: $dark-blue;
      font-family: 'Aeonik', sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: bold;
      line-height: 26px;
      margin-bottom: 26px;
      max-width: 600px;

      span {
        color: $dark-blue;
        display: block;
        margin-top: 10px;
        font-weight: normal;
      }
    }

    &__normal-price {
      color: $dark-blue;
      font-family: 'Aeonik', sans-serif;
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      margin-bottom: 14px;
      text-decoration-line: line-through;
    }

    .tag-zone {
      position: relative;
      width: 570px;
      background-color: #30405a;
      color: $white;
      border-radius: 30px;
      height: 60px;
      padding: 12px 30px;
      text-align: left;
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      span,
      .tag {
        font-size: 20px;
      }

      span {
        position: absolute;
        font-family: 'Aeonik', sans-serif;
        text-decoration: line-through;
      }

      .tag {
        height: calc(100% + 2px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        padding: 12px 30px;
        background-color: $white;
        color: $dark-blue;
        width: 300px;
        position: absolute;
        top: -1px;
        right: -1px;
        font-family: 'Aeonik', sans-serif;

        b {
          margin-left: 4px;
        }
      }
    }

    &__condition {
      color: $dark-blue;
      font-family: 'Aeonik', sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.08em;
      line-height: 14px;
      margin-bottom: 32px;
      text-transform: uppercase;

      b {
        display: block;
        margin-bottom: 10px;
      }
    }

    button {
      width: 100%;
      max-width: 245px;
      padding: 12px 30px;

      .btn__text {
        align-items: center;
        color: $white;
        display: flex;
        font-family: 'Aeonik', sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        text-align: center;
      }
    }
  }

  @media (max-width: 965px) {
    .malt-offer {
      &__title {
        font-size: 40px;

        svg {
          margin-right: 0;
          width: 120px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .malt-offer {
      padding: 40px 25px;

      &__catch-phrase {
        font-size: 20px;
        line-height: 24px;
      }

      &__title {
        display: flex;
        flex-direction: column;
        font-size: 26px;
        font-style: normal;
        font-weight: normal;
        line-height: 31px;

        svg {
          width: 105px;
          height: 29.94px;
        }
      }

      &__description {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 14px;
      }

      &__normal-price {
        font-size: 20px;
        font-style: normal;
        font-weight: normal;
        line-height: 24px;
      }

      .tag-zone {
        background-color: transparent;
        flex-direction: column;
        position: static;
        padding: 0;
        margin-bottom: 25px;
        width: auto;
        height: auto;

        span,
        .tag {
          position: static;
        }

        span {
          color: $dark-blue;
          margin-bottom: 12px;
          font-size: 22px;
          line-height: 26px;
        }

        .tag {
          height: 60px;
          width: auto;
          font-size: 20px;
          line-height: 26px;
        }
      }

      &__condition {
        margin-bottom: 24px;
      }

      button {
        .btn__text {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
