.malt-skyrocket {
  display: grid;
  font-family: 'Aeonik', sans-serif;
  grid-template-columns: 40% 60%;
  background-color: #ebecef;
  border-radius: 3rem;
  padding: 6.5rem 10%;
  padding-left: 0;
  color: #00112f;

  @media (max-width: 767px) {
    grid-gap: 10%;
    grid-template-columns: 100%;
    padding-left: 10%;
  }

  #skyrocket-content {
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  #calendar-svg-wrapper {
    display: flex;
    align-items: center;
  }

  #skyrocket-title {
    font-size: 4.6rem;
    line-height: 5.5rem;
    margin-bottom: 3rem;

    @media (max-width: 767px) {
      font-size: 3.2rem;
      line-height: 4.4rem;
      margin-bottom: 1.5rem;
    }
  }

  #skyrocket-subtitle {
    font-size: 2.2rem;
    line-height: 2.6rem;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
}
