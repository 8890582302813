@import 'colors.scss';
@import 'main.scss';
@import 'fonts.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'home.scss';
@import 'shiba.scss';
@import 'login.scss';
@import 'signup.scss';
@import 'password.scss';
@import 'code-verification.scss';
@import 'select-a-list.scss';
@import 'spacing.scss';
@import 'content-container.scss';
@import 'situations-list.scss';
@import 'links.scss';
@import 'breadcrumbs.scss';
@import 'button.scss';
@import 'form.scss';
@import 'rich-editor.scss';
@import 'languageSelector.scss';
@import 'privacyPolicy.scss';
@import 'cookieBanner.scss';
@import 'modal.scss';

//Landing
@import 'landingHeader.scss';
@import 'title.scss';
@import 'presentation.scss';
@import 'companiesExample.scss';
@import 'bannerWithImageAndText.scss';
@import 'methodDescription.scss';
@import 'podcastExtracts.scss';
@import 'lessonsDescription.scss';
@import 'pricingCards.scss';
@import 'customOffer/index.scss';
@import 'landingFooter.scss';
@import 'courseSummary.scss';
@import 'warranty.scss';
@import 'paymentConfirmation.scss';
@import 'not-found.scss';
@import 'hamburgerMenu.scss';
@import 'slick-slider.scss';
@import 'media.scss';
@import 'contactUs.scss';
@import 'emailCollector.scss';
@import 'companiesSection.scss';
@import 'pillBadge.scss';
@import 'modalContentE4DWaitingList.scss';
@import 'modalContentE4RWaitingList.scss';

//Malt Landing
@import 'maltLandingBanner.scss';
@import 'maltLandingBullets.scss';
@import 'maltLandingOffer.scss';
@import 'maltLandingHelp.scss';
@import 'maltLandingTimeline.scss';
@import 'maltLandingClients.scss';
@import 'maltLandingWeekAtClub.scss';
@import 'maltLandingHowClubWorks.scss';
@import 'maltLandingSkyrocket.scss';
@import 'maltLandingWhyE4D.scss';
@import 'maltLandingKnowMore.scss';
@import 'maltLandingHamburgerMenu.scss';
@import 'promoBar.scss';
@import 'promoBarContentE4R.scss';
@import 'promoBarContentMaltLanding.scss';

// Service Not Available Page
@import 'serviceNotAvailable.scss';

html {
  font: 300 62.5% 'Aeonik', sans-serif;
}

body {
  color: black;
  font-family:
    'Aeonik',
    'Menlo',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 20px;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#content-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  flex: 1;
}

.hide {
  display: none;
}

pre {
  color: unset;
  font-size: 100%;
}

.text-center {
  text-align: center;
}
