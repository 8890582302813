#malt-week-at-club {
  color: #00112f;
  margin-top: 5rem;

  h2 {
    font-size: 4.6rem;
    font-weight: normal;
    line-height: 5.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 767px) {
      font-size: 2.6rem;
      line-height: 3.1rem;
      margin-bottom: 0;
    }
  }

  p {
    font-size: 2.2rem;
    line-height: 3rem;
    padding-left: 10%;
    padding-right: 10%;
    margin: 3rem auto;

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 767px) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  #malt-week-at-club__video-wrapper {
    margin: 3rem auto;
    margin-bottom: 2rem;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;

    iframe {
      width: 100%;
      height: 419px;
      max-width: 749px;
      max-height: 419px;
    }

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;

      iframe {
        max-height: 225px;
      }
    }
  }

  small {
    color: #6e798b;
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    margin-top: 5rem;

    @media (max-width: 767px) {
      margin-top: 3rem;
      font-size: 1.2rem;
    }
  }
}
