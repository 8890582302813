.course-summary {
  border: 1px solid $royal-blue;
  border-radius: 2.1rem;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 3rem 0;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      padding: 0 3rem;
    }

    > div {
      align-items: center;
      border-left: 1px solid $royal-blue;
      border-right: 1px solid $royal-blue;
      display: flex;
      flex-direction: column;
      padding: 3.3rem 0;

      @media (max-width: 767px) {
        border: none;
        border-bottom: 1px solid $royal-blue;
        border-top: 1px solid $royal-blue;
      }

      > div {
        color: $royal-blue;
        font-family: 'Montserrat', sans-serif;
        font-size: 4rem;
        font-weight: 700;
        line-height: 5rem;
      }

      &:first-child,
      &:last-child {
        border: none;
      }

      span {
        font-family: 'Mulish', sans-serif;
        font-size: 1.6rem;
      }
    }
  }
}
