#contact-us {
  padding-bottom: 10rem;
  padding-top: 5rem;

  @media (max-width: 767px) {
    padding-bottom: 6rem;
    padding-top: 3rem;
  }

  #contact-us--description-text {
    color: $eerie-black;
    font-size: 2.2rem;
    font-weight: bold;
    padding-bottom: 1rem;

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  hr {
    border-top: 1px solid #828282;
  }

  .contact-us--block {
    display: grid;
    grid-template-columns: 7% 68% 25%;
    padding: 1rem 0;

    @media (max-width: 991px) {
      grid-template-columns: 7% 58% 35%;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .contact-us--image-logo {
    align-items: center;
    display: flex;

    @media (max-width: 767px) {
      justify-content: center;
      padding-top: 1rem;
    }

    img {
      width: 35px;
    }
  }

  .contact-us--text {
    align-items: center;
    display: flex;

    @media (max-width: 767px) {
      justify-content: center;
      padding-top: 2rem;
    }

    h3 {
      color: $eerie-black;
      font-size: 3.5rem;
      margin-bottom: 0;
      padding-bottom: 0;

      @media (max-width: 767px) {
        font-size: 3rem;
      }
    }
  }

  .contact-us--button-block {
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      padding-top: 2rem;
    }

    .contact-us--button:focus {
      background: $purple-hover;
    }
  }
}
