#malt-why-e4d {
  margin: 6rem auto;
  color: #00112f;
  padding: 0 10%;

  @media (max-width: 767px) {
    padding: 0;
  }

  #whye4d-title {
    font-size: 4.6rem;
    line-height: 5.5rem;
    font-weight: normal;

    @media (max-width: 767px) {
      font-size: 3.2rem;
      line-height: 4.4rem;
    }
  }

  .content {
    font-size: 2.2rem;
    line-height: 3rem;
    margin: 3rem auto;

    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }

  small {
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: bold;
    letter-spacing: 0.08em;
    margin-top: 2rem;
  }
}
