.our-method {
  border-radius: 3rem 3rem 0 0;
  padding-bottom: 8rem;
  padding-top: 8rem;

  @media (max-width: 767px) {
    margin-top: -5.5rem;
  }

  h3 {
    font-weight: 200;
  }

  @media (max-width: 767px) {
    .big-content-container {
      text-align: center;
    }
  }
}

.our-method-grid {
  margin: 0 auto;
  padding: 6rem 0;
  width: 72%;

  @media (max-width: 767px) {
    width: 90%;
  }

  h4 {
    padding-bottom: 1rem;

    @media (max-width: 767px) {
      padding-top: 2rem;
    }
  }

  p {
    @media (max-width: 767px) {
      padding-bottom: 1rem;
    }
  }

  .method-step {
    display: grid;
    grid-gap: 3.7%;
    grid-template-columns: 69px 1fr;
    padding-bottom: 5rem;

    @media (max-width: 767px) {
      align-items: center;
      display: flex;
      flex-flow: column wrap;
      grid-template-columns: 100%;
    }

    p {
      color: white;
    }
  }

  .method-step:nth-child(1) h4 {
    color: #f1827f;
  }

  .method-step:nth-child(2) h4 {
    color: #a6c487;
  }

  .method-step:nth-child(3) h4 {
    color: #9e91dc;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 0;

    .method-step {
      padding-bottom: 2rem;
    }
  }

  @media (min-width: 768px) {
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
}
