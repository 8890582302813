.home {
  color: var(--color-text);
  font-family: 'Aeonik', sans-serif;
  font-size: 2.4rem;
  line-height: 2.88rem;

  h2 {
    font-family: 'Aeonik', sans-serif;
    font-size: 5.5rem;
    font-weight: 700;
    letter-spacing: -0.25rem;
    line-height: 7rem;

    @media (max-width: 767px) {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }

  p {
    color: $dark-blue;
    font-size: 2rem;
    line-height: 3rem;

    @media (max-width: 767px) {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
  }

  a {
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 2.2rem;
  }

  h3 {
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: -0.15rem;
    line-height: 5rem;
    padding-bottom: 3rem;

    @media (max-width: 767px) {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.6rem;
  }
}
